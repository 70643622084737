body {
    font: 1rem "Century Gothic", Futura, sans-serif;
    background-color: #333;
    color: white;
  }
  /* .screen {
    width: 1000px;
  } */
  .nav-left {
    /* text-align: center; */
    /* position: center; */
    /* border: 1px solid orange; */
    width: 79%;
    float: left;
  }

  .nav-right {
    /* border: 1px solid orangered; */
    /* text-align: center; */
    /* position: center; */
    float: right;
    width: 21%;
  }
  
  li {
    list-style: none;
  }

  .button {
    background-color: black;
    border: 1px solid rgb(70, 5, 37);
    color: #ccc;
    margin-right: 0px;
    margin-bottom: 10px;
    padding-bottom: 50px;
    text-align: center;
    touch-action: manipulation; /* disables double tap zoom in on iphone */
  }

  .die {
    font-size: 1.5rem;
    font-weight: bold;
    float: left;
    width: 80px;
    padding: 15px;
    margin-right: 10px;
  }

  .reset_btn {
    float: right;
    width: 90px;
    font-weight: normal;
    font-size: 1.25rem;
    margin-top: 2px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .clearfix {
    clear: both;
  }

  .results {
    float: left;
    background-color: white;
    color: black;
    height: 1500px;
    font-size: 2rem;
    width: 55%;
  }

  .intro {
    font-size: 1.50rem;
    margin: 10px;
  }

  .result {
    float: left;
    margin: 10px;
  }

  .stats {
    float: right;
    width: 45%;
    height: 1500px;
    background-color: rgb(70, 5, 37);
    color: white;
  }

  .stats-left {
    clear: both;
    float: left;
    margin-right: 20px;
  }
  .rolls {
    font-size: 1.5rem;
    margin: 10px;
    margin-bottom: 20px;
  }

  .stats-right {
    clear: right;
    float: left;
    margin: 10px;
  }

   ol, ul {
     margin: 0px;
     padding: 1px;
    /* border: 1px solid magenta; */
  }
  
  /* .game {
    display: flex;
    flex-direction: row;
  } */
  /*
  .board-row:after {
    clear: both;
    content: "";
    display: table;
  }
  
  .status {
    margin-bottom: 10px;
  }
  
  .square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
    height: 34px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 34px;
  }
  
  .square:focus {
    outline: none;
  }
  
  .kbd-navigation .square:focus {
    background: #ddd;
  }
  

  
  .game-info {
    margin-left: 20px;
  } */
  
